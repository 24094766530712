"use strict";

var _jsxFileName = "/opt/build/repo/packages/landing-gatsby/plugins/gatsby-plugin-react-helmet-canonical-urls/src/wrap-page.js";

var React = require('react');

var _require = require('react-helmet'),
    Helmet = _require.Helmet;

var minimatch = require("minimatch");

var defaultPluginOptions = {
  noTrailingSlash: false,
  noQueryString: false,
  noHash: false
};

var isExcluded = function isExcluded(excludes, element) {
  if (!Array.isArray(excludes)) return false;
  element = element.replace(/\/+$/, '');
  return excludes.some(function (exclude) {
    if (exclude instanceof RegExp) return element.match(exclude);
    return minimatch(withoutTrailingSlash(element), withoutTrailingSlash(exclude));
  });
};

var withoutTrailingSlash = function withoutTrailingSlash(path) {
  return path === "/" ? path : path.replace(/\/$/, "");
};

module.exports = function (_ref, pluginOptions) {
  var element = _ref.element,
      location = _ref.props.location;

  if (pluginOptions === void 0) {
    pluginOptions = {};
  }

  var options = Object.assign({}, defaultPluginOptions, pluginOptions);

  if (options.siteUrl && !isExcluded(options.exclude, location.pathname)) {
    var pathname = location.pathname || '/';
    if (options.noTrailingSlash && pathname.endsWith('/')) pathname = pathname.substring(0, pathname.length - 1);
    var myUrl = "" + options.siteUrl + pathname;
    if (!options.noQueryString) myUrl += location.search;
    if (!options.noHash) myUrl += location.hash;
    return React.createElement(React.Fragment, null, React.createElement(Helmet, {
      link: [{
        rel: 'canonical',
        key: myUrl,
        href: myUrl
      }],
      __source: {
        fileName: _jsxFileName,
        lineNumber: 42
      },
      __self: this
    }), element);
  }

  return element;
};